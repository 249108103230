import React, { Component } from "react";

import Client from "./Clients";


class Portfolio extends Component {
  render() {

    return (
      <React.Fragment>
      
        <div className="portfolio-wrapper">
          <div className="container">
            <div className="row">
          <Client/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Portfolio;
