import React, { Component } from "react";

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area" style={{marginTop: '40px'}}>
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Ready To Do This</span>
                                        <h2>Let's get <br /> to work</h2>
                                        <a className="rn-button-style--2"  href="/contact" style={{marginTop: '-100px'}}>
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <ul className="ft-link">
                                                    <li><a href="mailto:sales@taatgendevelopment.com">sales@taatgendevelopment.com</a></li>
                                                    {/* <li><a href="mailto:hr@example.com">hr@example.com</a></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2024 Taatgen Development LLC. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;