import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

function TrackPageViews() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    ReactGA.pageview(location.pathname + location.search);
    console.log("Page view tracked:", location.pathname);
  }, [location]);

  return null; // This component doesn't render anything
}

export default TrackPageViews;
