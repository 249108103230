import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
//import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiUsers ,FiGrid } from "react-icons/fi";

import { FcCollaboration } from "react-icons/fc";
import { MdHighQuality } from "react-icons/md";



const ServiceList = [
  {
      icon: <FcCollaboration />,
      title: 'Collaboration is Key:',
      description: <p>
      <b>We're not just a team:</b> We're your partners in digital innovation. Our Git prowess ensures seamless collaboration and version control, keeping us all on the same page – literally.
      </p>
  },

  {
    icon: <MdHighQuality />,
    title: 'Our Quality Promise:',
    description:  <p>
    <b>Beyond Bug-Fixing: </b>We don't just test; we future-proof. Using SinonJS, MochaJS, Jest, and Cypress, we ensure your product remains robust under any circumstance. As E2E development experts, we cover every stage from initial concept to the final click, ensuring every pixel is perfect with tools like Protractor, WebDriver, and Selenium.
    </p>
},
  {
      icon: <FiGrid  />,
      title: 'Why We Stand Out:',
      description:  <p>
      <b> Deep Tech Know-How:</b> Our arsenal goes beyond HTML, CSS, and JavaScript. We're the wizards of frameworks like Bootstrap and Zurb Foundation, and we conjure up digital magic with ReactJS, AngularJS, NextJS, and Gatsby. MERN Stack Maestros: Our dedication to ReactJS goes beyond the basics of coding. It's about designing web interfaces that mirror the vibrancy and responsiveness of your audience. Backend Brilliance: MongoDB, REST APIs – you name it, we’ve mastered it. We stitch together front and backend technologies into a seamless digital tapestry.
      </p>
  },
  {
      icon: <FiUsers />,
      title: 'Praise from Our Clients:',
      description: <p>
      <b>Our clients are at the heart of everything we do,</b> and their satisfaction speaks volumes. They have consistently lauded our team for not only meeting but exceeding expectations. From our proactive communication to our problem-solving acumen, clients appreciate the unique blend of technical expertise and customer-centric approach we bring to each project. Their testimonials are a testament to our commitment to excellence and innovation in the realm of web development. At Taatgen Development LLC, we’re more than developers; we're architects of the digital age. If you're looking for a team that values innovation, craftsmanship, and cutting-edge solutions, let's talk. We're ready to elevate your web applications to unparalleled heights. Let’s create something extraordinary together.
      </p>
  }
]
class About extends Component {

  render() {
    let title = "About Taatgen Development",
      description = `Taatgen Development LLC founded in 2023 by our founder Mr. Mike Taatgen who is a senior software engineer with expertise in diverse sectors of software application development. Mr. Mike is assessed as an Expert-Vetted Talent - the top 1% — pre-screened by Upwork Talent Managers and experts in their field. We are based in Irving, United States and our team includes industry experts in providing complete software solution including Front-End, Back-End, Mobile Application Development & Software Testing.
        Mr. Mike Taatgen along with iron-bond working relationship with his colleague Mr. Murtaza Usman, a senior front-end software engineer, developed an environment of satisfying diverse client requirements with utmost technological excellence.`;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About"/>

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"About TDL"} />
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 ">
                <div className="col-lg-3" >
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-12.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>

                <div className="col-lg-9">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>

                    </div>
                    </div>

                    <div className="col-lg-12 col-12 mt_md--50" style={{marginTop: '70px'}}>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </div>
                            ))}
                        </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                  <div className="section-title text-center" >
                    <h3 className="centered-heading" >Our Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
       
  <div style={{marginTop: '50px'}}>
        <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default About;
