import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import Helmet from "../component/common/Helmet";
import Brand from "../elements/Brand";
import { MdOutlineFindInPage, MdOutlineDesignServices, MdDeveloperMode, MdSupportAgent } from "react-icons/md";

class MainDemo extends Component {
  render() {
    return (
      <Fragment>
        <Helmet pageTitle="Home" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <SliderOne />
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area about-position-top pt--80 pb--120">
          <About />
        </div>
        {/* End About Area */}

        <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>

        {/* Start Brand Area  */}
        <div className="rn-brand-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12  pb--60">
                <div className="section-title text-center">
                  <h3 className="centered-heading">Our Favorite Code-Stack</h3>
                </div>
              </div>
              <div className="col-lg-12">
                <Brand branstyle="branstyle--2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--200 bg_color--1">
          <div className="portfolio-sacousel-inner mb--80">
            <Portfolio />
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pt--140 pb--110 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="centered-heading">Our Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>

        <div style={{ backgroundColor: '#f8f9fc', padding: 50 }}>
          <div className="section-title text-center" >
            <h3 className="centered-heading" style={{ marginTop: 20, marginBottom: 20 }}>Process</h3>
          </div>
          <div className="row"  >
            <div
              className=" col-lg-3 col-md-3 col-sm-6 col-12"

            >
              <div className="process-card">
                <MdOutlineFindInPage size={'42px'} color="#0cc7ff" />
                <p style={{ textAlign: 'center', marginTop: '10px' }}>Discovery</p>
              </div>

            </div>
            <div
              className=" col-lg-3 col-md-3 col-sm-6 col-12"

            >
              <div className="process-card">
                <MdOutlineDesignServices size={'42px'} color="#0cc7ff" />
                <p style={{ textAlign: 'center', marginTop: '10px' }}>Design</p>
              </div>

            </div>
            <div
              className=" col-lg-3 col-md-3 col-sm-6 col-12"

            >
              <div className="process-card">
                <MdDeveloperMode size={'42px'} color="#0cc7ff" />
                <p style={{ textAlign: 'center', marginTop: '10px' }}>Development</p>
              </div>

            </div>
            <div
              className=" col-lg-3 col-md-3 col-sm-6 col-12"

            >
              <div className="process-card">
                <MdSupportAgent size={'42px'} color="#0cc7ff" />
                <p style={{ textAlign: 'center', marginTop: '10px' }}>Support</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default MainDemo;
