import React, { Component } from "react";
import { Helmet } from "react-helmet";
import titleList from "../../constant/titles";

class PageHelmet extends Component {
  formatPageTitle = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  render() {
    const formattedPageTitle = this.formatPageTitle(this.props.pageTitle);

    const canonicalUrl =
      `${process.env.PUBLIC_URL}/${formattedPageTitle}` ||
      `${process.env.PUBLIC_URL}/`; // Default URL if not provided
    const title =
      titleList[this.props.pageTitle] ||
      "Top-Tier Software Development Services | Taatgen Development LLC";
    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
          <meta
            name="description"
            content="Taatgen Development creates custom websites, apps, SEO, and e-commerce solutions with expert engineers and 24/7 global support. Elevate your brand today!"
          />
          <meta
            name="keywords"
            content="Taatgen Development, digital agency, custom websites, mobile-responsive websites, SEO, mobile app development, e-commerce solutions, data analytics, software solutions, web development, corporate websites, WordPress development, custom website design, web technologies, Upwork, expert engineers, global support, digital problems, brand development, Full Stack Development, ReactJS, AngularJS, Node.js, front-end development, back-end development, JavaScript, web applications, software engineering, technology frameworks, responsive design, UI/UX design"
          />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
