const titleList = {
  Home: "Welcome to Taatgen Development LLC | Custom Software Solutions",
  About: "Learn About Our Journey | Taatgen Development LLC",
  Service:
    "Our Services | Taatgen Development LLC | Tailored Software Solutions",
  Contact: "Get in Touch | Contact Taatgen Development LLC Today",
  "Portfolio Details":
    "Project Portfolio | Taatgen Development LLC's Success Stories",
  Blog: "Tech Insights and Updates | Taatgen Development LLC Blog",
  "Blog Details": "In-Depth Article | Taatgen Development LLC Blog Insights",
  Team: "Meet Our Experts | Taatgen Development LLC Team",
  Counters: "Our Achievements in Numbers | Taatgen Development LLC Counters",
  Testimonial: "What Our Clients Say | Taatgen Development LLC Testimonials",
  "Video Popup":
    "Watch Our Work in Action | Taatgen Development LLC Video Showcase",
  Gallery: "Explore Our Work | Taatgen Development LLC Project Gallery",
  "Client Logo": "Our Trusted Clients | Taatgen Development LLC",
  "Progress Bar": "Our Progress and Growth | Taatgen Development LLC",
  "Contact Form": "Reach Out to Us | Taatgen Development LLC Contact Form",
  "Google Map":
    "Find Us Easily | Taatgen Development LLC Location on Google Maps",
  Columns: "Structured Information Display | Taatgen Development LLC",
  "Pricing Table":
    "Affordable Pricing Plans | Taatgen Development LLC Services",
};

export default titleList;
